import React from "react";
import {Select} from "../Select";

const TopBar = function({ select, toggle, language }) {
  return (
    <div className="list-reset flex flex-wrap items-center justify-between my-2">
      <Select {...language} />
    </div>
  );
}
 export default TopBar;
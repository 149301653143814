import DATABASE from "../../assets/database.png"
import FRONTEND1 from "../../assets/frontend.png"
import FRONTEND2 from "../../assets/frontend2.png"
import BACKEND1 from "../../assets/backend1.png"
import ASSIGNMENT from "../../assets/assignment.png"

const Home = () => (
    <div className="flex w-full flex-col  items-center justify-start flex-grow">
        <div className="  text-5xl text-center leading-normal uppercase sm:capitalize sm:text-6xl font-extrabold  m-12 text-indigo-700" >
        Development Assesment Submissions
      </div>  
      
          
      {/* <div className="container mx-auto w-full overflow-hidden relative">
        <div className="w-full h-full absolute z-0">
            <div className="w-1/4 h-full absolute z-40 left-0" style={{background: "linear-gradient(to right, lightblue 0%, rgba(255, 255, 255, 0) 100%)"}}></div>
            <div className="w-1/4 h-full absolute z-40 right-0" style={{background: "linear-gradient(to left, lightblue 0%, rgba(255, 255, 255, 0) 100%)"}}></div>
        </div>

        <div className=" z-0 carousel-items flex items-center justify-center pointer-events-none" style={{width: "fit-content" , animation: "carouselAnim 5s infinite alternate linear"}}>
                
                <img src={FRONTEND2} alt="database" width="340px" className="carousel-item pointer-events-none -z-10" />
                <img src={DATABASE} alt="database" width="200px" className="carousel-item pointer-events-none "/>
                <img src={ASSIGNMENT} alt="database" width="300px" className="carousel-item pointer-events-none z-0"/>
                <img src={BACKEND1} alt="database"width="300px" className="carousel-item pointer-events-none"/>
                <img src={FRONTEND1} alt="database"width="230px" className="carousel-item pointer-events-none"/>

        </div>
        </div> */}
    
    </div>

)

export default Home

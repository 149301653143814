import card from "../../../../assets/card-top.jpg";
import { DeleteIcon, EditIcon } from "./Icons";

const Card = ({ book, setShowModal, setInitialData, setBooks }) => {
  const handleEditClick = () => {
    setInitialData(book);
    setTimeout(() => {
      setShowModal(true);
    });
  };
  const handleDeleteClick = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/v1/backend/LMS`,
      {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ isbn: book.isbn }),
      }
    );
    if (response.ok) {
      const { data: books } = await response.json();
      setBooks(books);
    } else {
      const { error } = await response.json();
      console.log("error", error);
    }
  };
  return (
    <div className="w-full xs:w-[45%] md:w-[31%] rounded-2xl p-3 pl-5 bg-dimBlue overflow-hidden shadow-lg mb-10 hover:shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px]">
      <div className="flex mb-4">
        <div className="flex-auto text-black capitalize flex items-center font-extrabold text-2xl mt-3 mb-3 h-16 overflow-ellipsis">
          {book.title}
        </div>
        <div className=" flex float-right p-0">
          <button
            className="px-2 rounded hover:opacity-60 hover:bg- text-gray-800"
            onClick={handleEditClick}
          >
            <EditIcon />
          </button>
          <button
            className=" px-0.5 hover:opacity-60 text-red-800 rounded"
            onClick={handleDeleteClick}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
      <hr className="border-gray-800 p-2" />
      <img className="w-full" src={card} alt="Sunset in the mountains" />
      <div className="px-3 py-4">
        <p className="text-gray-700 text-base h-28 xs:h-36">{book.summary}</p>
      </div>
      <div className="px-3 pt-4 pb-2">
        <div>
          <span className="inline-block rounded-full text-sm mr-4 font-bold w-14">
            Author :
          </span>
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {book.author}
          </span>
        </div>
        <span className="inline-block rounded-full text-sm mr-4 font-bold w-14">
          ISBN :
        </span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          {book.isbn}
        </span>

        <div>
          <span className="inline-block rounded-full text-sm mr-4 font-bold w-14">
            Genre :
          </span>
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {book.genre}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Card;

const navItems = [
  {
    to: "task1",
    path: "M5 3V19H21V21H3V3H5ZM20.2929 6.29289L21.7071 7.70711L16 13.4142L13 10.415L8.70711 14.7071L7.29289 13.2929L13 7.58579L16 10.585L20.2929 6.29289Z",
    label: "Sum of Evens",
    source: "https://img.icons8.com/color/48/123.png",
  },
  {
    to: "task2",
    label: "Student Data",
    source: "https://img.icons8.com/fluency/48/student-female.png",
  },
  {
    to: "task3",
    label: "LMS",
    source: "https://img.icons8.com/3d-fluency/48/library.png",
  },
  {
    to: "task4",
    label: "SQL Schema",
    source: "https://img.icons8.com/ultraviolet/40/sql.png",
  },
];

export default navItems;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./MyLinks";

const NavLinks = ({ setOpen }) => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  return (
    <>
      {links.map((link, index) => (
        <li key={index}>
          <div className="text-left sm:cursor-pointer group">
            <h1
              className="w-full my-2 sm:my-4 py-4 px-5 sm:px-2  md:px-3 text-2xl sm:text-xl md:text-2xl rounded-lg sm:hover:bg-red-100 font-bold sm:hover:text-black flex justify-between items-center group"
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
                setSubHeading("");
              }}
            >
              {link.name}
              <span className="text-xs md:text-xl sm:invisible sm:hidden inline">
                <ion-icon
                  name={`${
                    heading === link.name ? "chevron-up" : "chevron-down"
                  }`}
                ></ion-icon>
              </span>
              {link.submenu && (
                <span className="sm-hide text-xl sm:mt-2.5 sm:ml-1   invisible sm:visible sm:block  group-hover:rotate-180 group-hover:-mt-2">
                  <ion-icon name="chevron-down"></ion-icon>
                </span>
              )}
            </h1>
            {link.submenu && (
              <div>
                <div className=" absolute top-20 invisible group-hover:sm:visible hover:sm:visible group-hover:sm:block hover:sm:block">
                  <div className="py-3">
                    <div
                      className="w-4 h-4 left-3 absolute 
                    mt-1 bg-white rotate-45"
                    ></div>
                  </div>
                  <div className="bg-white p-5">
                    {link.sublinks.map((sublink) => (
                      <div
                        key={sublink.name}
                        className="py-1 capitalize text-gray-400 hover:text-blue-800 hover:border-1 border-black"
                      >
                        <Link to={sublink.link} className="hover:text-primary ">
                          <h1 className="text-lg font-semibold hover:bg-red-100 rounded-lg px-2 py-2">
                            <img
                              src={sublink.source}
                              alt={sublink.name}
                              className="w-9 mr-2 h-auto inline"
                            />
                            {sublink.name}
                          </h1>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={` -mt-4 ml-2
            ${heading === link.name ? "sm:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {link.submenu &&
              link.sublinks.map((sublink) => (
                <div key={sublink.name}>
                  <div>
                    <Link
                      to={sublink.link}
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <h1
                        onClick={() =>
                          subHeading !== sublink.name
                            ? setSubHeading(sublink.name)
                            : setSubHeading("")
                        }
                        className="py-3 pl-7 font-semibold flex items-center sm:pr-0 pr-5"
                      >
                        <img
                          src={sublink.source}
                          alt={sublink.name}
                          className="w-9 mr-3 h-auto inline"
                        />
                        {sublink.name}
                      </h1>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </li>
      ))}
    </>
  );
};

export default NavLinks;

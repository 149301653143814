import React, { useState } from "react";
import colorsArray from "./colors";
import "./styles.css";

function Dropdown() {
  const [selectedOption, setSelectedOption] = useState(colorsArray[0].color);
  const [shown, setShown] = useState(false);
  const handleChange = (e) => {
    setSelectedOption(e.target.id);
  };
  const handleMouseEnter = (e) => {
    if (!shown) {
      return;
    }
    const label = e.target;
    const color = e.target.id;
    label.style.backgroundColor = color;
  };

  const handleMouseLeave = (e) => {
    const label = e.target;
    label.style.backgroundColor = "";
  };

  document.addEventListener("click", () => setShown(false));

  return (
    // <div className="w-4/6 bg-white flex flex-row flex-wrap justify-center items-center mb-24 rounded-lg shadow-lg font-bold p-4">
      <div className={`mb-24 w-4/6 flex flex-col gap-3 xs:gap-0 xs:flex-row xs:justify-around font-bold  `} tabIndex={1}>
        <span
          className={` border-[0.06em] border-transparent  max-h-[2em] outline-none  whitespace-nowrap text-left cursor-pointer top-[0.5em]  overflow-hidden xs:mr-[1em] min-w-[12em] text-[24px] capitalize relative inline-block dropdown-el ${shown ? "expanded" : ""} mb-4`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShown(!shown);
          }}
        >
          {colorsArray.map((color) => {
            return (
              <React.Fragment key={color.color}>
                <input
                  type="radio"
                  name="sortType"
                  value={color.color}
                  id={color.color}
                  checked={selectedOption === color.color}
                  readOnly
                  className="w-1 h-1 inline-block absolute"
                />
                <label
                  id={color.color}
                  htmlFor={color.color}
                  onClick={handleChange}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={`relative block h-[2em] leading-[2em] xs:pl-[1em] xs:pr-[3em] cursor-pointer  `}
                >
                  {color.display}
                </label>
              </React.Fragment>
            );
          })}
        </span>
        <div className="flex items-center justify-center">
          <div
            className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] flex items-center justify-center border-transparent  max-h-[2em] outline-none  whitespace-nowrap cursor-pointer  overflow-hidden mr-[1em] min-w-[12em] text-[24px] capitalize relative p-2 rounded-lg text-black font-extrabold"
            style={{ backgroundColor: selectedOption }}
          >
            {" "}
            Change my color
          </div>
        </div>
      </div>
  );
}

export default Dropdown;

import { useState } from "react"

const ResponsiveComponent = () => {
  return (
    <div className="frontend-container flex flex-col items-center flex-grow w-full">
      <div className="flex w-full justify-around flex-row mt-10 pointer-events-none">
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
        />
        <h1 className="text-5xl font-extrabold text-black   ">
          Responsive Layout
        </h1>
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
        />
      </div>

      <div className="m-10 mt-5 bg-slate-200 p-5">
        <div className="flex my-2 ">
          <span className="font-bold text-xl px-2">10. </span>
          <p className="text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            Add CSS styles to make your webpage responsive. Focus on ensuring
            that the webpage layout adjusts gracefully when viewed on smaller
            screens, such as mobile devices or tablets.
          </p>
        </div>
      </div>
      
    </div>
  )
}
export default ResponsiveComponent;

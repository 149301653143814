/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import data from "./demo.json";
import Modal from "../modal";
import Card from "../Card";

const LibraryDemo = () => {
  const initialDataData = {
    title: "",
    author: "",
    summary: "",
    isbn: "",
    genre: "",
  };
  const [showModal, setShowModal] = useState(false);
  const [books, setBooks] = useState(data.books);
  const [initialData, setInitialData] = useState(initialDataData);

  useEffect(() => {
    const getBooks = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v1/backend/LMS`
      );
      const data = await response.json();
      if (response.ok) {
        setBooks(data.data);
      } else {
        console.log("error", data.error);
      }
    };
    getBooks();
  }, []);

  return (
    <div className="w-5/6 flex flex-col">
      <div className="flex flex-row mt-10 mb-10 text-black items-center">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-extrabold flex-auto">
          Loya's Library
        </h1>
        <button
          className="text-lg sm:text-xl md:text-2xl font-bold rounded-lg px-2 py-1 sm:px-8 sm:py-2 border-2
         border-blue-500 text-black-500 hover:bg-blue-500 hover:text-blue-100 duration-300
         hover:shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]"
          onClick={() => setShowModal(true)}
        >
          Add Book
        </button>
        <Modal
          data={initialData}
          showModal={showModal}
          setShowModal={setShowModal}
          setBooks={setBooks}
          setInitialData={setInitialData}
        />
      </div>
      <div className="flex flex-wrap justify-between">
        {books.length > 0
          ? books.map((book) => {
              return (
                <Card
                  key={book.isbn}
                  book={book}
                  setShowModal={setShowModal}
                  setInitialData={setInitialData}
                  setBooks={setBooks}
                />
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default LibraryDemo;

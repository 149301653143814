import Footer from "../../components/footer"
import Navbar from "../../components/navbar"

const PageLayout = ({ children }) => (
  <div className=" -z-50 relative w-screen overflow-hidden">
    <Navbar />
    <div className="-z-50 relative  min-h-screen flex flex-col">{children}</div>
    <footer className="bg-primary text-white  w-full">
      <Footer />
    </footer>
  </div>
)

export default PageLayout

const Table = ({ data, removeData }) => {
  const renderHeader = () => {
    let headerElement = [
      "id",
      "name",
      "email",
      "age",
      "comment",
      "ip",
      "image",
      "delete",
    ];

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  const renderBody = () => {
    return (
      data &&
      data.map(
        ({ id, name, email, age, comment, ip, location, snapshot: image }) => {
          return (
            <tr key={id}>
              <td>{id}</td>
              <td>{name}</td>
              <td>{email}</td>
              <td>{age}</td>
              <td>{comment}</td>
              <td>{ip}</td>
              <td>
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${image.slice(1)}`}
                  alt="img"
                  height={100}
                  width={100}
                />
              </td>

              <td className="operation">
                <button className="button" onClick={() => removeData(id)}>
                  Delete
                </button>
              </td>
            </tr>
          );
        }
      )
    );
  };

  return (
    <div className="flex justify-center flex-col m-10">
      <h1
        className="text-center text-5xl text-gray-800 font-extrabold mb-6 tracking-widest"
        style={{ textShadow: "0 10px 18px #2c482e, 0 -0.5px 0.5px #aeffb4" }}
      >
        User Database
      </h1>
      <table id="users">
        <thead>
          <tr>{renderHeader()}</tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </table>
    </div>
  );
};

export default Table;

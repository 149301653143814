import { useRef, useState } from "react";

const JSbasicsComponent = () => {
  const [num, setNum] = useState("");
  const [isOdd, setIsOdd] = useState(false);
  const [guess, setGuess] = useState(5);
  const [randNum, setRandNum] = useState(Math.floor(Math.random() * 10) + 1);
  const [fruit, setFruit] = useState("");
  const [fruits, setFruits] = useState([]);
  const [showFruits, setShowFruits] = useState(false);
  const [guessArray, setGuessArray] = useState([]);
  const evenOddInputRef = useRef(null);

  const checkNumber = (e) => {
    const check = document.createElement("p");
    if (num === "") {
      check.innerHTML = "please enter a number";
    } else {
      check.innerHTML = `${num} is an ${isOdd ? "Odd" : "Even"} number.`;
    }
    check.classList.add("text-center");
    document.getElementById("add").appendChild(check);
    document.getElementById("checkButton").setAttribute("disabled", "");
    evenOddInputRef.current.focus();

    setTimeout(() => {
      document.getElementById("checkButton").removeAttribute("disabled");
      check.style.display = "none";
    }, 2000);
    setNum("");
  };

  const guessNumber = (e) => {
    const res = document.createElement("p");
    res.classList.add("px-2");
    let AlreadyGuessed = false;
    guessArray.forEach(({ number }) => {
      if (guess === number) {
        AlreadyGuessed = true;
      }
    });
    if (AlreadyGuessed) {
      res.innerHTML = "Already guessed this number";
      document.getElementById("guess").appendChild(res);
      e.target.setAttribute("disabled", "");
      setTimeout(() => {
        e.target.removeAttribute("disabled");
        res.style.display = "none";
      }, 2000);
      return;
    }
    const diff = guess - randNum;
    let result;
    let arrayLength = guessArray.length;
    if (diff === 0) {
      res.innerHTML = "✅ Congratulation you are right";
      document.getElementById("guess").appendChild(res);
      setTimeout(() => {
        setGuessArray([]);
        setRandNum(Math.floor(Math.random() * 10) + 1);
      }, 2000);
    } else {
      if (diff < -5) {
        result = "TOO LOW";
      } else if (diff <= -1) {
        result = "LOW";
      } else if (diff > 5) {
        result = "TOO HIGH";
      } else {
        result = "HIGH";
      }
      setGuessArray((prev) => [...prev, { number: guess, status: result }]);
      arrayLength = arrayLength + 1;
    }
    if (arrayLength === 3) {
      res.innerHTML =
        "❌ Your guesses are over. The right number is " + randNum;
      document.getElementById("guess").appendChild(res);
      setTimeout(() => {
        setGuessArray([]);
        setRandNum(Math.floor(Math.random() * 10) + 1);
      }, 2000);
    }
    e.target.setAttribute("disabled", "");
    setTimeout(() => {
      e.target.removeAttribute("disabled");
      res.style.display = "none";
    }, 2000);
  };

  const addFruit = (e) => {
    const res = document.createElement("p");
    res.classList.add("px-2");
    if (fruit && fruit.length > 2) {
      const isFruitPresent = fruits.some(
        (f) => f.toLowerCase() === fruit.toLowerCase()
      );

      if (!isFruitPresent) setFruits((current) => [...current, fruit]);
      else {
        res.innerText = `${fruit} already present`;
      }
      document.getElementById("fruit").appendChild(res);
      e.target.setAttribute("disabled", "");
      setTimeout(() => {
        e.target.removeAttribute("disabled");
        res.style.display = "none";
      }, 2000);
      setFruit("");
    }
  };

  return (
    <div className=" frontend-container flex flex-col items-center flex-grow w-full">
      <div className="flex w-full justify-around tems-center flex-row mt-10 pointer-events-none">
        <img
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20 "
        />
        <h1 className=" text-3xl xs:text-5xl font-extrabold text-black  ">
          JavaScript Basics
        </h1>
        <img
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
      </div>

      <div className="m-6 xs:m-10 xs:mt-5 bg-slate-200  p-3 xs:p-5">
        <div className="flex my-2 ">
          <span className="font-bold text-md xs:text-xl px-0.5 xs:px-2">
            4.{" "}
          </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg  leading-relaxed italic hover:not-italic transition-all duration-1000">
            Create a JavaScript function that takes an integer as an argument
            and displays a message if the number is even or odd when a button is
            clicked.
          </p>
        </div>
      </div>
      <div
        id="add"
        className=" w-[87.2%] xs:w-auto flex flex-col xs:flex-row flex-wrap justify-center xs:items-center mb-10 bg-white rounded-lg shadow-lg font-bold p-4"
      >
        <label htmlFor="num" className="m-2 text-red-900">
          {" "}
          Enter a number
        </label>
        <input
          type="number"
          name="num"
          id="num"
          className="p-2 mx-2 xs:mx-0 bg-blue-200 text-center outline-none"
          value={num}
          autoComplete="off"
          autoFocus
          ref={evenOddInputRef}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              checkNumber(e);
            }
            if (e.key === "e" || e.key === ".") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            setNum(e.target.value);
            e.target.value % 2 === 0 ? setIsOdd(false) : setIsOdd(true);
          }}
        />
        <button
          type="button"
          id="checkButton"
          className=" bg-lime-300 hover:bg-lime-500 transition-all p-2  px-4 rounded-md m-3 xs:m-2 mx-24 xs:mx-4 border-2 border-red-300 hover:border-red-800"
          onClick={checkNumber}
        >
          Check
        </button>
      </div>
      <div className="m-6 xs:m-10 xs:mt-5 bg-slate-200  p-3 xs:p-5">
        <div className="flex my-2 ">
          <span className="font-bold xs:text-xl px-0.5 xs:px-2">5. </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            Develop a JavaScript function that generates a random number between
            1 and 10 and asks the user to guess it. Provide feedback to the
            user, indicating whether their guess is correct, too low, or too
            high. Limit the number of guesses to three.
          </p>
        </div>
      </div>

      <div className="w-[87.2%] xs:w-auto flex flex-col flex-wrap justify-center items-center mb-10 bg-white rounded-lg shadow-lg font-bold p-4">
        <div
          id="guess"
          className=" flex flex-col gap-2 xs:gap-0 xs:flex-row flex-wrap justify-center items-center bg-white rounded-lg shadow-lg font-bold p-4"
        >
          <label htmlFor="num" className="m-2 text-red-900">
            {" "}
            Choose a number {"["}1,10{"]"}
          </label>
          <div className="flex justify-center items-center">
            <button
              className=" hover:brightness-75 "
              onClick={() => {
                if (guess > 1) setGuess(guess - 1);
              }}
            >
              <img
                width="30"
                src="https://img.icons8.com/3d-fluency/100/minus.png"
                alt="minue"
                className="mx-2 pointer-events-none"
              />
            </button>
            <input
              type="number"
              name="num"
              id="num"
              className="p-2 w-4/6 xs:w-auto bg-blue-200 rounded-full text-center"
              value={guess}
              disabled
            />
            <button
              className=" hover:brightness-75 "
              onClick={() => {
                if (guess < 10) setGuess(guess + 1);
              }}
            >
              <img
                width="30"
                src="https://img.icons8.com/3d-fluency/100/plus.png"
                alt="add"
                className="mx-2 pointer-events-none"
              />
            </button>
          </div>

          <button
            type="button"
            className=" bg-lime-300 hover:bg-lime-500 transition-all p-2 px-4 rounded-md m-2 mx-4 border-2 border-red-300 hover:border-red-800"
            onClick={guessNumber}
          >
            Check
          </button>
        </div>
        {guessArray.length !== 0 &&
          guessArray.map((guess, index) => {
            return (
              <div key={index} className="m-2">
                {guess.number} IS {guess.status}
              </div>
            );
          })}
      </div>
      <div className="m-6 xs:m-10 xs:mt-5  p-3 xs:p-5 bg-slate-200 ">
        <div className="flex my-2 ">
          <span className="font-bold  text-lg xs:text-xl px-0.5 xs:px-">
            6.{" "}
          </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            Write JavaScript code to create an array of your favorite fruits.
            Then, create a function to display this list as an ordered list when
            a button is clicked.
          </p>
        </div>
      </div>
      <div
        id="fruit"
        className=" w-[87.2%] gap-2 xs:gap-0 xs:w-auto flex flex-col xs:flex-row flex-wrap justify-center items-center mb-10 bg-white rounded-lg shadow-lg font-bold p-4"
      >
        <label htmlFor="fruiit" className="m-2 text-red-900">
          {" "}
          Enter your favourite fruit
        </label>
        <input
          type="text"
          name="num"
          id="fruiit"
          value={fruit}
          autoComplete="off"
          className="p-2 bg-blue-200"
          onChange={(e) => {
            setFruit(e.target.value);
          }}
        />
        <div>
          <button
            type="button"
            className=" bg-lime-300 hover:bg-lime-500 transition-all p-2 px-4 rounded-md m-2 mx-4 border-2 border-red-300 hover:border-red-800"
            onClick={addFruit}
          >
            Add
          </button>
          <button
            type="button"
            className=" bg-lime-300 hover:bg-lime-500 transition-all p-2 px-4 rounded-md m-2 mx-4 border-2 border-red-300 hover:border-red-800"
            onClick={() => setShowFruits(!showFruits)}
          >
            Show Fruits
          </button>
        </div>
      </div>
      <div
        className={` w-[87.2%] xs:w-auto fruits text-red-500 mb-20 xs:mb-10 ${
          showFruits ? "" : "hidden"
        }`}
      >
        <ul className="flex flex-wrap items-center justify-center xs:justify-start flex-row">
          {fruits.length === 0 ? (
            <div className="text-white text-2xl font-bold">
              Fruits Array is Empty{" "}
            </div>
          ) : (
            fruits.map((item, index) => {
              return (
                <li
                  key={index}
                  className="flex m-2 p-2 bg-white rounded-xl font-bold"
                >
                  {item}
                </li>
              );
            })
          )}
        </ul>
      </div>
    </div>
  );
};
export default JSbasicsComponent;

const codeblocks = {
	
	python: `class Book:
  def __init__(self, title, author, isbn):
      self.title = title
      self.author = author
      self.isbn = isbn

  def display_info(self):
      print(f"Title: {self.title}, Author: {self.author}, ISBN: {self.isbn}")


class Library:
  def __init__(self):
      self.books = []

  def add_book(self, book):
      self.books.append(book)
      print(f"Book added: {book.title}")

  def remove_book(self, isbn):
      for book in self.books:
          if book.isbn == isbn:
              self.books.remove(book)
              print(f"Book removed: {book.title}")
              return
      print(f"Book with ISBN {isbn} not found in the library.")

  def list_books(self):
      if not self.books:
          print("The library is empty.")
      else:
          print("Books in the library:")
          for book in self.books:
              book.display_info()


# Create a library
my_library = Library()

# Add some books
book1 = Book("The Catcher in the Rye", "J.D. Salinger", "9780316769488")
book2 = Book("To Kill a Mockingbird", "Harper Lee", "9780061120084")
book3 = Book("1984", "George Orwell", "9780451524935")

my_library.add_book(book1)
my_library.add_book(book2)
my_library.add_book(book3)

# List all books in the library
my_library.list_books()

# Remove a book by ISBN
my_library.remove_book("9780061120084")

# List books again after removal
my_library.list_books()
`,
"node.js":`class Book {
  constructor(title, author, isbn) {
      this.title = title;
      this.author = author;
      this.isbn = isbn;
  }

  displayInfo() {
      console.log("Title: " + this.title + ", Author: " + this.author + ", ISBN: " + this.isbn);
  }
}

class Library {
  constructor() {
      this.books = [];
  }

  addBook(book) {
      this.books.push(book);
      console.log("Book added: " + book.title);
  }

  removeBook(isbn) {
      for (let i = 0; i < this.books.length; i++) {
          if (this.books[i].isbn === isbn) {
              console.log("Book removed: " + this.books[i].title);
              this.books.splice(i, 1);
              return;
          }
      }
      console.log("Book with ISBN " + isbn + " not found in the library.");
  }

  listBooks() {
      if (this.books.length === 0) {
          console.log("The library is empty.");
      } else {
          console.log("Books in the library:");
          for (let i = 0; i < this.books.length; i++) {
              this.books[i].displayInfo();
          }
      }
  }
}

// Create a library
const myLibrary = new Library();

// Add some books
const book1 = new Book("The Catcher in the Rye", "J.D. Salinger", "9780316769488");
const book2 = new Book("To Kill a Mockingbird", "Harper Lee", "9780061120084");
const book3 = new Book("1984", "George Orwell", "9780451524935");

myLibrary.addBook(book1);
myLibrary.addBook(book2);
myLibrary.addBook(book3);

// List all books in the library
myLibrary.listBooks();

// Remove a book by ISBN
myLibrary.removeBook("9780061120084");

// List books again after removal
myLibrary.listBooks();`
	
};

export default codeblocks;
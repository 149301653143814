import { useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import sample from "./codeblocks";
import TopBar from "./TopBar";
import LibraryDemo from "./libraryDemo";

const LibraryComponent = () => {
  const [language, changeLanguage] = useState("jsx");
  const [languageDemo, changeDemo] = useState(sample["python"]);
  return (
    <div className=" bg-slate-400 flex flex-col items-center flex-grow w-full">
      <div className="flex w-full justify-around items-center flex-row mt-10 pointer-events-none">
        <img

          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
        <h1 className="text-xl xs:text-5xl font-extrabold text-black   ">
          Library Management System
        </h1>
        <img
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
      </div>

      <div className="m-6 xs:m-10 xs:mt-5  p-3 xs:p-5 bg-slate-200">
        <div className="flex my-2 ">
          <span className="font-bold text-sm xs:text-xl px-0.5 xs:px-2">10. </span>
          <div className="pt-0.5 xs:pt-0 text-sm xs:text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            Write a Python/Node.js/Java program that simulates a basic library
            management system. Implement two classes: Book and Library.
            <br />
            • The Book class should have attributes like title, author, and
            ISBN. It should also have a method to display book information.
            <br />
            • The Library class should maintain a list of books and implement
            the following methods:
            <br />
            <div className="pl-10 ">
              • add_book(book): Adds a book to the library.
              <br />
              • remove_book(isbn): Removes a book from the library by ISBN.
              <br />
              • list_books(): Lists all the books in the library.
              <br />
            </div>
            You should demonstrate the usage of these classes by adding and
            removing books from the library and listing the books.
          </div>
        </div>
      </div>
      <div className="code w-5/6 mb-10 text-xs">
        <TopBar
          language={{
            value: language,
            onChange: (e) => {
              changeDemo(sample[e.target.value]);
              return changeLanguage(e.target.value);
            },
            options: Object.keys(sample).map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            )),
          }}
        />
        <CopyBlock
          language={"python"}
          text={languageDemo}
          theme={dracula}
          codeBlock
        />
      </div>
      <LibraryDemo />
    </div>
  );
};
export default LibraryComponent;

import React from 'react';
import CaretDown from '../CaratDown';

export const Select = function(props) {
	return (
		<div className=" flex relative w-64 mb-2 ">
			<select
				{...props}
				value={props.value}
				className="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
				{props.options}
			</select>
			<div className="pointer-events-none absolute flex items-center px-2 text-grey-darker right-0 top-2">
				<CaretDown className="fill-current h-4 w-4" />
			</div>
		</div>
	);
}



const Footer = () => {

  return (

        <div className="m-auto py-2 text-center">
            <p> @gauravrana05 &copy; 2023</p>
        </div>
  );
};

export default Footer;
import { useState } from "react";
import numToWords from "../../../utils/numToWords.js";

const FormsComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    age: "",
    comment: "",
  });
  const [age, setAge] = useState();

  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const ipResponse = await fetch("https://api.ipify.org?format=json");
      const ipData = await ipResponse.json();
      const userIP = ipData.ip;
      const data = {
        ...formData,
        age,
        ip: userIP,
      };
      await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/users`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      setIsSuccess(true);
    } catch (error) {
      console.error(error);
      alert("Did not succeed");
    }
  };

  const handleFocus = (e) => {
    if (e.target.value === "") {
      return;
    }
    const name = e.target.name;
    setFormData((prev) => {
      return { ...prev, [name]: age };
    });
  };

  const handleFocusOut = (e) => {
    setAge(e.target.value);
    const value = numToWords(e.target.value);
    const name = e.target.name;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "age") {
      if (isNaN(value)) {
        e.target.setCustomValidity("Please enter a number");
        e.target.reportValidity();
        return;
      } else if (value < 0 || value > 99) {
        return;
      }
    }
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  return (
    <div className="frontend-container z-10 flex flex-col items-center flex-grow w-full">
      <div className=" flex w-full justify-around items-center flex-row mt-10">
        <img
          src="https://img.icons8.com/color/48/html-5--v1.png"
          alt="html-5--v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20 "
        />
        <h1 className=" text-3xl xs:text-5xl font-extrabold text-black   ">
          HTML & CSS
        </h1>
        <img
          src="https://img.icons8.com/plasticine/100/css3.png"
          alt="css3"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
      </div>

      <div className="m-6 xs:m-10 xs:mt-5  p-3 xs:p-5 bg-slate-200 ">
        <div className="flex my-2">
          <span className="font-bold text-sm xs:text-xl px-0.5 xs:px-2">
            1.{" "}
          </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            Create an HTML form for a simple survey that includes fields for a
            name, email address, age, and a comment box. Add appropriate labels
            for each field.
          </p>
        </div>
        <div className="flex my-2">
          <span className="font-bold text-md xs:text-xl px-0.5 xs:px-2">
            2.{" "}
          </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            Style the form using CSS to make it visually appealing. Apply at
            least two different CSS properties to each of the form fields, such
            as changing background color, font size, or border style.
          </p>
        </div>
        <div className="flex my-2">
          <span className="font-bold text-md xs:text-xl px-0.5 xs:px-2">
            3.{" "}
          </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            Implement CSS rules to center-align.
          </p>
        </div>
      </div>
      <div className="wrapper  rounded-lg w-[87.2%] xs:w-4/6 mb-10">
        <div
          className={`  ${
            isSuccess ? "hidden bg-blue-50" : "block"
          } mt-10 rounded-lg pt-2 pb-3 transition-all px-6 text-white text-2xl xs:text-4xl font-bold hover:bg-gray-700 `}
        >
          Custom Form
        </div>
        <form
          method="post"
          id="form"
          onSubmit={handleSubmit}
          className={` w-[75%] xs:w-[60%] ${
            isSuccess ? "hidden bg-blue-50" : "block"
          }`}
        >
          <div className="relative pb-0 px-0 my-5 field-input">
            <input
              autoComplete="off"
              required
              type="text"
              id="name"
              name="name"
              placeholder=""
              className=" w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 text-lg xs:text-2xl rounded-md py-2 px-2 xs:py-3 xs:px-3 block appearance-none leading-normal focus:border-blue-600 hover:border-blue-400"
              pattern=".{4,}"
              value={formData.name}
              onInvalid={(e) =>
                e.target.setCustomValidity("Must Be Longer Than 4 Characters")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              onChange={handleChange}
            />
            <label
              htmlFor="name"
              className="rounded-lg absolute top-2 text-lg xs:text-2xl xs:top-3 left-0 text-gray-900 pointer-events-none transition duration-200 ease-in-out bg-white px-1 xs:px-2 text-grey-darker"
            >
              Name
            </label>
          </div>
          <div className="relative pb-0 px-0 my-5 mt-10 field-input">
            <input
              required
              type="email"
              id="email"
              name="email"
              className=" w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 text-lg xs:text-2xl rounded-md py-2 px-2 xs:py-3 xs:px-3 block appearance-none leading-normal focus:border-blue-600 hover:border-blue-400 text-gray-900"
              placeholder=""
              onInvalid={(e) =>
                e.target.setCustomValidity("Please Enter a valid Email")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              value={formData.email}
              onChange={handleChange}
            />
            <label
              htmlFor="email"
              className="rounded-lg absolute top-2 text-lg xs:text-2xl xs:top-3 left-0 text-gray-900 pointer-events-none transition duration-200 ease-in-out bg-white px-1 xs:px-2 text-grey-darker"
            >
              Email Address
            </label>
          </div>
          <div className="flex w-full items-center justify-cetner pt-[10px] xs:pt-[20px] field">
            <label
              className="w-[27%] xs:w-[40%] text-gray-400 text-xl xs:text-2xl pl-[10px] xs:pr-[20px]"
              htmlFor="age"
            >
              Age
            </label>
            <input
              className="outline-none w-[75%] p-3 ml-10 rounded-lg text-lg bg-white form-age-field focus:bg-white"
              autoComplete="off"
              required
              type="text"
              id="age"
              name="age"
              placeholder="Enter your Age"
              onInvalid={(e) =>
                e.target.setCustomValidity("Please Enter a valid Age")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              value={formData.age}
              onChange={handleChange}
              onBlur={handleFocusOut}
              onFocus={handleFocus}
            />
          </div>
          <div className="form-comment-group w-full flex justify-center items-center pt-7 field">
            <textarea
              className="form-comment-field w-full text-sm xs:text-2xl focus:outline-none focus:shadow-outline border border-gray-300 rounded-md py-3 px-3 block appearance-none leading-normal hover:border-blue-400 focus:border-blue-600"
              autoComplete="off"
              type="input"
              required
              id="comment"
              placeholder="Enter your comments here"
              name="comment"
              rows="3"
              value={formData.comment}
              onChange={handleChange}
            ></textarea>
            <label className="hidden w-20% text-white pr-5" htmlFor="comment">
              Comment
            </label>
          </div>
          <div className="w-full flex items-center justify-center">
            <button
              className=" text-white cursor-pointer font-bold text-lg xs:text-2xl rounded-md px-5  my-12 mx-8 relative uppercase z-1 border-[1px] border-solid outline-none border-white tracking-[0.3rem] py-2 xs:py-4 xs:px-28 bg-[darkcyan] hover:bg-[#56807d] focus:bg-[#48726f] btn v1"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
        <div className={!isSuccess ? "hidden container" : "container"}>
          <img
            width="94"
            height="94"
            src="https://img.icons8.com/3d-fluency/94/ok.png"
            alt="ok"
          />
          <div className="mx-auto text-center text-xl mt-4 sm:text-2xl">
            {" "}
            Successfully Submitted.{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormsComponent;

import { useEffect, useState } from "react";
import Logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import NavLinks from "./NavLinks"
const Navbar = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [open]);

  return (
    <nav className="bg-white sticky">
      <div className="flex items-center font-medium justify-between">
        <div className=" z-50 px-5 xs:px-7 py-5 sm:w-auto w-full flex items-center justify-between">
          <div className=" sm:px-7 flex items-center">
            <img
              src={Logo}
              alt="logo"
              className="sm:cursor-pointer h-10  sm:h-12 md:h-14"
            />
            <span className="px-1 font-bold text-2xl sm:text-3xl md:text-4xl text-red-700">
              Assessment
            </span>
          </div>

          <div
            className=" text-3xl sm:hidden text-red-700"
            onClick={() => setOpen(!open)}
          >
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>
        <ul className="px-10 sm-hide invisible sm:visible  sm:flex capatalize items-center gap-1 md:gap-3">
          <li>
            <Link
              to="/"
              className=" my-4 py-3 pl-0 pr-2 md:px-3 text-xl md:text-2xl rounded-lg hover:bg-red-100 font-bold hover:text-black  inline-block"
              onClick={() => {
                setOpen(false);
              }}
            >
              Home
            </Link>
          </li>
          <NavLinks setOpen={setOpen} />
          <li>
            <Link
              to="/"
              className=" my-4 py-3 px-2 md:px-3 text-xl md:text-2xl rounded-lg hover:bg-red-100 font-bold hover:text-black  inline-block"
              onClick={() => {
                setOpen(false);
              }}
            >
              About
            </Link>
          </li>
        </ul>
        {/* Mobile nav */}
        <ul
          className={` 
         sm:hidden bg-white fixed overscroll-y-none w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <li>
            <Link
              to="/"
              className="w-full mt-2 py-5 px-5 text-2xl font-bold  inline-block"
              onClick={() => {
                setOpen(false);
              }}
            >
              Home
            </Link>
          </li>
          <NavLinks setOpen={setOpen} />
          <li>
            <Link
              to="/about"
              className="w-full py-5 px-5 text-2xl font-bold  inline-block"
              onClick={() => {
                setOpen(false);
              }}
            >
              About
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;

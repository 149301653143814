import { Outlet } from "react-router-dom";
import React, { useState } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import navItems from './tasks'
const Backend = () => {
  const [collapsed, setSidebarCollapsed] = useState(true);
  return (
    <div
      className={` md:grid md:min-h-screen md:transition-[grid-template-columns] md:ease-in-out md:duration-300 ${
        collapsed ? "md:grid-cols-sidebar-collapsed" : "md:grid-cols-sidebar"
      }`}
      >
      {/* sidebar */}
      <div className="med-hide">
      <Sidebar
        collapsed={collapsed}
        setCollapsed={() => setSidebarCollapsed((prev) => !prev)}
        navItems={navItems}
        />
        </div>
      {/* content */}
      <div className="min-h-screen flex flex-col">
        <Outlet />
        </div>
    </div>
  );
};

export default Backend;

import { useState } from "react";
import Carousel from "../carousel/newCarousel";
import Dropdown from "../dropdown";

const DOMComponent = () => {
  const [hidden, setHidden] = useState(true);
  return (
    <div className="frontend-container flex flex-col items-center flex-grow w-full">
      <div className="w-[95%] flex xs:w-full justify-around items-center flex-row mt-10 pointer-events-none">
        <img
          src="https://img.icons8.com/color-glass/48/domain.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20 "

        />
        <h1 className="text-3xl xs:text-5xl font-extrabold text-black   ">
          Dom Manipulation
        </h1>
        <img
          src="https://img.icons8.com/fluency/24/web.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
      </div>

      <div className="m-6 xs:m-10 xs:mt-5  p-3 xs:p-5 bg-slate-200 ">
        <div className="flex my-2 ">
          <span className="font-bold text-md xs:text-xl px-0.5 xs:px-2">7. </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            In your HTML document, create a button with the text "Toggle Text."
            Using JavaScript, toggle the visibility of a paragraph when this
            button is clicked. The paragraph should initially be hidden, and
            clicking the button should toggle between hiding and showing it.
          </p>
        </div>
      </div>
      <div
        id="add"
        className="  flex flex-row flex-wrap justify-center items-center mb-5 bg-white rounded-lg shadow-lg font-bold p-4"
      >
        <p hidden={hidden} id="toggle" className="m-2 text-red-900">
          {" "}
          I am VISIBLE
        </p>

        <button
          type="button"
          className=" bg-lime-300 hover:bg-lime-500 transition-all p-2 px-4 rounded-md m-2 mx-4 border-2 border-red-300 hover:border-red-800"
          onClick={() => setHidden((prev) => !prev)}
        >
          {hidden ? "Toggle Text" : "Hide"}
        </button>
      </div>

      <div className="m-6 xs:m-10 xs:mt-5  p-3 xs:p-5  bg-slate-200">
        <div className="flex my-2 ">
          <span className="font-bold text-sm xs:text-xl px-0.5 xs:px-2">8. </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            Build a JavaScript-based image gallery with at least five images.
            Implement previous and next buttons to navigate through the images.
            Ensure that the images loop when reaching the beginning or end of
            the gallery.
          </p>
        </div>
      </div>
      <Carousel />

      <div className="mb-36 m-6 xs:m-10 xs:mt-5  p-3 xs:p-5 bg-slate-200">
        <div className="flex my-2 ">
          <span className="font-bold text-sm xs:text-xl px-0.5 xs:px-2 ">9. </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            Create a dropdown select element in HTML with three options
            representing different colors. Write JavaScript code that changes
            the background color of a div when a color is selected from the
            dropdown.
          </p>
        </div>
      </div>
      {/* <Dropdown /> */}
    </div>
  );
};
export default DOMComponent;

export const links = [
  {
    name: "Frontend",
    submenu: true,
    sublinks: [
      {
        name: "HTML & CSS",
        source: "https://img.icons8.com/color/48/html-5.png",
        link: "/frontend/task1",
      },
      {
        name: "JavaScript Basics",
        source: "https://img.icons8.com/arcade/64/javascript.png",
        link: "/frontend/task2",
      },
      {
        name: "DOM Manipulation",
        source: "https://img.icons8.com/fluency/24/web.png",
        link: "/frontend/task3",
      },
    ],
  },
  {
    name: "Backend",
    submenu: true,
    sublinks: [
      {
        name: "Sum of Evens",
        source: "https://img.icons8.com/color/48/123.png",
        link: "/backend/task1",
      },
      {
        name: "Student Data",
        source: "https://img.icons8.com/fluency/48/student-female.png",
        link: "/backend/task2",
      },
      {
        name: "Library Management System",
        source: "https://img.icons8.com/3d-fluency/48/library.png",
        link: "/backend/task3",
      },
      {
        name: "SQL Schema",
        source: "https://img.icons8.com/ultraviolet/40/sql.png",
        link: "/backend/task4",
      },
    ],
  },
]

import { useEffect, useState } from "react";

import { cards as slides } from "./data";

export default function Carousel() {
  let [current, setCurrent] = useState(0);
  const [inter, setInter] = useState();


  let previousSlide = () => {
    if (current === 0) setCurrent(slides.length - 1);
    else setCurrent(current - 1);
  };

  let nextSlide = () => {
    if (current === slides.length - 1) setCurrent(0);
    else setCurrent(current + 1);
  };

  return (
    <div className="frontend-container w-[75%] xs:w-[50%] m-auto">
      <div className=" relative bg-gray-50 m-2 border-blue-200 rounded-lg border-[10px] ">
        <div className="w-[100%] h-[100%] overflow-hidden">
        <div
          className="flex drop-shadow-md rounded-md transition ease-out duration-1000"
          style={{
            transform: `translateX(-${current * 100}%)`,
          }}
          >
          {slides.map((s) => {
            return (
              <img
              key={s}
              className="pointer-events-none bg-blue-200 brightness-75 "
              src={s}
              alt=""
              />
              );
            })}
        </div>

            </div> 
        <div className=" absolute top-0 h-full w-full items-center flex text-white  text-3xl">
          <div className="justify-between items-center flex w-[100%] h-[100%] absolute ml-[-55px] xs:ml-[-90px]">
          <button onClick={previousSlide}>
            <img
              src="https://img.icons8.com/flat-round/64/left--v1.png"
              alt="left--v1"
              className="pointer-events-none w-10 h-10 xs:w-16 xs:h-16 "
              />
          </button>
              </div>
              <div className="justify-end items-center flex w-[100%] h-[100%] absolute ml-[55px] xs:ml-[90px]">

          <button onClick={nextSlide}>
            <img
              src="https://img.icons8.com/flat-round/64/right--v1.png"
              alt="right--v1"
              className="pointer-events-none  xs:ml-[160px] w-10 h-10  xs:w-16 xs:h-16"
              />
          </button>
              </div>
        </div>

        <div className="absolute bottom-0 py-4 flex justify-center items-center w-full">
          {slides.map((s, i) => {
            return (
              <div
                onClick={() => {
                  setCurrent(i);
                }}
                key={"circle" + i}
                className={`transition-all m-1.5 duration-1000 rounded-full  cursor-pointer  ${
                  i === current ? "bg-white w-3 h-3 xs:w-5 xs:h-5 m-0.5" : "bg-gray-500 w-2 h-2 xs:w-3 xs:h-3 hover:w-[1rem] hover:h-[1rem] xs:hover:w-4 xs:hover:h-4 hover:m-1 duration-0"
                }`}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./pages/home";
import Users from "./components/frontend/users";

import PageLayout from "./pages/pageLayout";
import Frontend from "./pages/frontend";
import Backend from "./pages/backend";
import FormsComponent from "./components/frontend/form";
import JSbasicsComponent from "./components/frontend/JSbasics";
import DOMComponent from "./components/frontend/domm";
import ResponsiveComponent from "./components/frontend/responsive"
import SumComponent from "./components/backend/sum";
import StudentComponent from "./components/backend/students";
import LibraryComponent from "./components/backend/library";
import SQLSchemaComponent from "./components/backend/sql";
function App() {
  return (
    <PageLayout>
      <Routes>
        <Route exact path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/frontend" element={<Frontend />}>
          <Route exact path="" element={<Navigate to="task1/" />} />
          <Route path="users" element={<Users />} />
          <Route path="task1" element={<FormsComponent />} />
          <Route path="task2" element={<JSbasicsComponent />} />
          <Route path="task3" element={<DOMComponent />} />
          <Route path="task4" element={<ResponsiveComponent />} />
          <Route path="task5" element={<Users />} />
        </Route>
        <Route path="/backend" element={<Backend />} >
        <Route exact path="" element={<Navigate to="task1/" />} />
          <Route path="task1" element={<SumComponent />} />
          <Route path="task2" element={<StudentComponent />} />
          <Route path="task3" element={<LibraryComponent />} />
          <Route path="task4" element={<SQLSchemaComponent />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </PageLayout>
  );
}

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./styles.css";

export default function Modal({
  showModal,
  setShowModal,
  setBooks,
  data,
  setInitialData,
}) {
  const [formData, setFormData] = useState(data);
  const [summaryData, setSummaryData] = useState("");
  const isDataPresent = data.title !== "";

  useEffect(() => {
    setFormData(data);
    setSummaryData(data.summary);
    if (formData.summary.length > 40) {
      const truncatedData = formData.summary.substring(0, 40) + "...";
      setFormData((prev) => {
        return { ...prev, summary: truncatedData };
      });
    }
  }, [showModal, data]);

  const handleChange = (e) => {
    const name = e.target.name;
    if (name === "isbn") {
      const isbn = e.target.value.toString();
      if (isbn.length > 13) {
        e.target.setCustomValidity(
          "ISBN should not be more than 13 characters"
        );
        e.target.reportValidity();
        setTimeout(() => e.target.setCustomValidity(""), [1000]);
        return;
      }
    }
    const value = e.target.value;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleFocus = (e) => {
    setTimeout(() => {
      e.target.rows = 3;
    }, [50]);
    setFormData((prev) => {
      return { ...prev, summary: summaryData };
    });
  };

  const handleFocusOut = (e) => {
    e.target.rows = 1;
    setSummaryData(formData.summary);
    if (formData.summary.length > 40) {
      const truncatedData = formData.summary.substring(0, 40) + "...";
      setFormData((prev) => {
        return { ...prev, summary: truncatedData };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.isbn.value === "") {
      e.target.isbn.setCustomValidity("ISBN is required");
      e.target.isbn.reportValidity();
      return;
    }
    if (e.target.isbn.value.toString().length < 13) {
      e.target.isbn.setCustomValidity(
        "ISBN should not be less than 13 characters"
      );
      e.target.isbn.reportValidity();
      return;
    }
    const bookData = { ...formData, summary: summaryData };
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/v1/backend/LMS`,
      {
        method: isDataPresent ? "PATCH" : "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bookData),
      }
    );
    const data = await response.json();
    if (response.ok) {
      setBooks(data.data);
    } else {
      console.log("error", data.error);
    }
    setShowModal(false);
    setFormData({
      title: "",
      author: "",
      summary: "",
      isbn: "",
      genre: "",
    });
    setSummaryData("");
    setInitialData({
      title: "",
      author: "",
      summary: "",
      isbn: "",
      genre: "",
    });
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mx-10">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-black rounded-t">
                  <h3 className="text-3xl font-semibold">Add Book</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      setShowModal(false);
                      setFormData({
                        title: "",
                        author: "",
                        summary: "",
                        isbn: "",
                        genre: "",
                      });
                      setSummaryData("");
                      setInitialData({
                        title: "",
                        author: "",
                        summary: "",
                        isbn: "",
                        genre: "",
                      });
                    }}
                  >
                    <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none hover:text-red-600">
                      x
                    </span>
                  </button>
                </div>
                <form className="w-full" onSubmit={handleSubmit}>
                  <div className="relative p-6 flex-auto ">
                    <div className="mx-auto max-w-md">
                      <div className="relative z-0 w-full mb-5">
                        <input
                          type="text"
                          name="title"
                          placeholder=""
                          required
                          autoFocus
                          disabled={isDataPresent}
                          value={formData.title}
                          onInvalid={(e) =>
                            e.target.setCustomValidity("Title is required")
                          }
                          onInput={(e) => e.target.setCustomValidity("")}
                          onChange={handleChange}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent disabled:text-gray-500 disabled:cursor-not-allowed border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          autoComplete="off"
                        />
                        <label
                          htmlFor="title"
                          className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
                        >
                          Title
                        </label>
                      </div>

                      <div className="relative z-0 w-full mb-5">
                        <input
                          type="text"
                          name="author"
                          required
                          disabled={isDataPresent}
                          placeholder=" "
                          value={formData.author}
                          onChange={handleChange}
                          onInvalid={(e) =>
                            e.target.setCustomValidity("Author is required")
                          }
                          onInput={(e) => e.target.setCustomValidity("")}
                          className="pt-3 pb-2 block w-full px-0 mt-0 disabled:text-gray-500 disabled:cursor-not-allowed bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          autoComplete="off"
                        />
                        <label
                          htmlFor="author"
                          className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
                        >
                          Author
                        </label>
                      </div>

                      <div className="relative z-0 w-full mb-5">
                        <textarea
                          name="summary"
                          placeholder=""
                          required
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 
                          border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black
                           border-gray-200"
                          autoComplete="off"
                          rows={1}
                          onInvalid={(e) =>
                            e.target.setCustomValidity("Summary is required")
                          }
                          onInput={(e) => e.target.setCustomValidity("")}
                          value={formData.summary}
                          onChange={handleChange}
                          onFocus={(e) => handleFocus(e)}
                          onBlur={(e) => handleFocusOut(e)}
                        />
                        <label
                          htmlFor="summary"
                          className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
                        >
                          Summary
                        </label>
                      </div>

                      <div className="flex flex-row space-x-4">
                        <div className="relative z-0 w-full">
                          <input
                            type="number"
                            name="isbn"
                            placeholder=" "
                            maxLength="13"
                            autoComplete="off"
                            disabled={isDataPresent}
                            onKeyDown={(e) =>
                              (e.key === "e" || e.key === ".") &&
                              e.preventDefault()
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                            value={formData.isbn}
                            onChange={handleChange}
                            className="pt-3 pb-2 block w-full px-0 mt-0 disabled:text-gray-500 disabled:cursor-not-allowed bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label
                            htmlFor="isbn"
                            className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
                          >
                            ISBN
                          </label>
                        </div>
                        <div className="relative z-0 w-full">
                          <input
                            type="text"
                            name="genre"
                            placeholder=" "
                            required
                            autoComplete="off"
                            onInvalid={(e) =>
                              e.target.setCustomValidity("Genre is required")
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                            value={formData.genre}
                            onChange={handleChange}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label
                            htmlFor="genre"
                            className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
                          >
                            Genre
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 border-2 font-bold uppercase shadow hover:shadow-2xl px-5 rounded-2xl py-2 text-sm 
                      hover:bg-red-500 hover:text-red-100 mr-5 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setFormData({
                          title: "",
                          author: "",
                          summary: "",
                          isbn: "",
                          genre: "",
                        });
                        setSummaryData("");
                        setInitialData({
                          title: "",
                          author: "",
                          summary: "",
                          isbn: "",
                          genre: "",
                        });
                        setShowModal(false);
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="rounded-2xl border-2 text-green-700 hover:bg-green-700 hover:text-green-100 
                      font-bold uppercase text-sm px-4 py-2 shadow hover:shadow-2xl mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

// inital data - dynamic data strutre works
import Mountain1 from "../../../assets/mountain1.jpg"
import Mountain2 from "../../../assets/mountain2.jpg"
import Mountain3 from "../../../assets/mountain3.jpg"
import Mountain4 from "../../../assets/mountain4.jpg"
import Deer1 from "../../../assets/deer1.jpg"
import Deer2 from "../../../assets/deer2.jpg"
import Parrot1 from "../../../assets/parrot1.jpg"
import Parrot2 from "../../../assets/parrot2.jpg"
import Eagle from "../../../assets/eagle.jpg"
import Squirrel from "../../../assets/squirrel.jpg"
import Tiger1 from "../../../assets/tiger1.jpg"
import Zebra from "../../../assets/zebra.jpg"

export const cards = [Tiger1, Zebra, Deer2, Parrot2, Eagle, Squirrel]

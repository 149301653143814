import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({ collapsed, setCollapsed, navItems }) => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const [textShown, setTextShown] = useState(collapsed);

  return (
    <div className="h-full bg-primary p-4 antialiased text-zinc-50 z-20">
      <div className="flex flex-col justify-between">
        <div
          className={`flex items-center border-b border-b-indigo-800 ${
            collapsed ? "py-4 justify-center" : "p-4 justify-between"
          }`}
        >
          {!collapsed && (
            <span className="whitespace-nowrap">
              <p className="text-white font-medium">Gaurav Rana</p>
              <p className="text-sm text-slate-200">gauravrana05</p>
            </span>
          )}
          <button
            className="grid place-content-center w-18 h-10 rounded-full "
            onClick={() => {
              setCollapsed(!collapsed);
              setTimeout(() => setTextShown(!textShown), 200);
            }}
          >
            <img
              className={` transition-all duration-1000 ${
                collapsed ? "w-28" : "w-10"
              }`}
              src={
                collapsed
                  ? "https://img.icons8.com/plasticine/100/000000/show-sidepanel.png"
                  : "https://img.icons8.com/plasticine/100/000000/hide-sidepanel.png"
              }
              alt="left-right"
            />
          </button>
        </div>

        <nav className="flex-grow">
          <ul className="my-4 flex flex-col gap-4 items-stretch">
            {navItems.map((item, index) => {
              return (
                <li
                  key={index}
                  className={` flex ${
                    collapsed
                      ? "rounded-full p-0  mx-2 w-14 h-14"
                      : "rounded-md mx-3 gap-4"
                  } transition-colors duration-300`}
                >
                  <Link
                    to={item.to}
                    className={` flex items-center w-full transition-all  ${
                      active.includes(item.to)
                        ? "border border-primary-light bg-primary-light/40"
                        : "hover:bg-primary-light/40"
                    } px-0 py-1 text-primary-white ${
                      collapsed ? "justify-center" : ""
                    }`}
                    onClick={() => {
                      setActive(item.to);
                    }}
                  >
                    <img
                      width="40"
                      height="48"
                      src={item.source}
                      alt="html-5"
                    />
                    <div className={`pl-2 ${collapsed ? "hidden" : ""}`}>
                      <span
                        className={`transition-opacity ease-in-out duration-400 ${
                          !textShown ? "pl-2 " : " hidden"
                        }`}
                      >
                        {item.label}
                      </span>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default Sidebar;

import React, { useEffect, useState } from "react";
import Table from "./table";
import "./styles.css";

const Users = () => {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/users`);
      const { data } = await response.json();
      setUserData(data);
    };
    getData();
  }, []);

  const removeData = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/users/${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        const del = userData.filter((item) => id !== item.id);
        setUserData(del);
      }
    } catch (error) {
    }
  };

  return (
    <div className="frontend-container"> 
      <Table data={userData} removeData={removeData} />
    </div>
  );
};

export default Users;

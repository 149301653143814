import { useState } from "react"
import { CopyBlock, dracula } from "react-code-blocks";
import sample from "./codeblocks"
import TopBar from "./TopBar";

import SQL_DATABASE from '../../../assets/sql_database.svg'
import SQL_DATABASE_MOBILE from '../../../assets/sql_database_mobile.svg'
const SQLSchemaComponent = () => {
  const [language, changeLanguage] = useState("postgre");
  const [languageDemo, changeDemo] = useState(sample["postgre"]);
  return (
    <div className=" bg-slate-400 flex flex-col items-center flex-grow w-full">
      <div className="flex w-full justify-around items-center flex-row mt-10 pointer-events-none">
        <img
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
        <h1 className="text-3xl  xs:text-5xl font-extrabold text-black   ">SQL Schema</h1>
        <img
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
      </div>

      <div className="m-6 xs:m-10 xs:mt-5  p-3 xs:p-5 bg-slate-200">
        <div className="flex my-2 ">
          <span className="font-bold text-lg xs:text-xl px-0.5 xs:px-2">10. </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg l leading-relaxed italic hover:not-italic transition-all duration-1000">
            Design a SQL database schema for a simple e-commerce platform. The
            database should include tables for products, customers, orders, and
            order items. Define the structure of each table and specify the
            relationships between them. Provide SQL statements to create the
            tables and constraints. You can use any SQL database system you
            prefer (e.g., MySQL, PostgreSQL).
          </p>
        </div>
      </div>
      <div className="pointer-events-none database flex justify-center  md:flex mb-10 ">
        <img src={SQL_DATABASE} alt="database" />
      </div>
      <div className="pointer-events-none  flex justify-center mb-10 sm:hidden w-4/6">
        <img src={SQL_DATABASE_MOBILE} alt="database" />
      </div>
      <div className="code w-[87.2%] xs:w-5/6 mb-10 text-xs xs:text-lg">
      <TopBar
        language={{
          value: language,
          onChange: e => {
            changeDemo(sample[e.target.value]);
            return changeLanguage(e.target.value);
          },
          options: Object.keys(sample).map(lang => (
            <option key={lang} value={lang}>
              {lang}
            </option>
          ))
        }}
      
      />
      <CopyBlock
          language={"python"}
          text={languageDemo}
          theme={dracula}
          wrapLines={true}
          codeBlock
        />
      </div>
    </div>
  )
}
export default SQLSchemaComponent

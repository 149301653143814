import { useState } from "react";

const SumComponent = () => {
  const [number, setNumber] = useState(231);
  const [evenSum, setEvenSum] = useState(0);
  const [numbers, setNumbers] = useState([]);
  const [shown, setShown] = useState(false);

  const addNumber = (e) => {
    const res = document.createElement("p");
    if (number) {
      const isNumberPresent = numbers.some((num) => num === number);
      if (!isNumberPresent) setNumbers((current) => [...current, number]);
      else {
        res.classList.add("px-2");
        res.innerText = `${number} already present`;
      }
      document.getElementById("number").appendChild(res);
      setTimeout(() => {
        res.style.display = "none";
      }, 1000);
      setNumber("");
    }
  };

  const sumEven = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/v1/backend/sumofEvens`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ numbersArray: numbers }),
      }
    );
    const sum = await response.json();
    setEvenSum(sum);
    setShown(true);
    setTimeout(() => {
      setShown(false);
    }, [1500]);
  };

  return (
    <div className=" bg-slate-400 flex flex-col items-center  flex-grow w-full">
      <div className="flex w-full justify-around items-center flex-row mt-10 pointer-events-none">
        <img
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
        <h1 className=" text-2xl xs:text-5xl font-extrabold text-black   ">
          Sum of Even Numbers
        </h1>
        <img
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
      </div>

      <div className="m-6 xs:m-10 xs:mt-5  p-3 xs:p-5 bg-slate-200 ">
        <div className="flex my-2 ">
          <span className="font-bold text-sm xs:text-xl px-0.5 xs:px-2">1. </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg  leading-relaxed italic hover:not-italic transition-all duration-1000">
            Write a Python/Node.js/Java function that takes a list of integers
            as input and returns the sum of all even numbers in the list. Name
            your function sum_of_evens. For example, if the input list is [1, 2,
            3, 4, 5, 6], the function should return 12.
          </p>
        </div>
      </div>
      <div
        id="number"
        className=" w-[87.2%] xs:w-auto flex gap-3 xs:gap-0 flex-col xs:flex-row flex-wrap justify-center items-center mb-10 bg-white rounded-lg shadow-lg font-bold p-4"
      >
        <input
          type="number"
          className="border-2 border-red-600 p-2 rounded-lg"
          value={number}
          onInput={(e) => {
            if (e.target.value > 1000) {
              e.target.value = 1000;
            }
            if (e.target.value < 0) {
              e.target.value = 0;
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addNumber();
            }
            if (e.key === "e" || e.key === ".") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            setNumber(e.target.value);
          }}
        />

        <button
          type="button"
          className=" bg-lime-300 hover:bg-lime-500 transition-all p-2 px-4 rounded-md m-2 mx-4 border-2 border-red-300 hover:border-red-800"
          onClick={addNumber}
        >
          {" "}
          Add Number to array
        </button>
        <button
          type="button"
          className=" bg-lime-300 hover:bg-lime-500 transition-all p-2 px-4 rounded-md m-2 mx-4 border-2 border-red-300 hover:border-red-800"
          onClick={sumEven}
        >
          {" "}
          Even Sum
        </button>
      </div>
      <div className={`numbers text-red-500 mb-10 w-3/6`}>
        <ul className="flex flex-wrap  justify-center xs:justify-normal  flex-row">
          {numbers.map((item, index) => {
            return (
              <li
                key={index}
                className="flex m-2 p-2 w-16 justify-center  bg-white rounded-xl font-bold"
              >
                {item}
              </li>
            );
          })}
        </ul>
      </div>
      <div className={`numbers text-voilet-800 mb-10 ${shown ? "" : "hidden"}`}>
        <div className="flex items-end  text-xl font-bold p-3 px-6 rounded-xl bg-white">
          The sum of even number is{" "}
          <span className="text-black text-3xl px-2 font-extrabold">
            {evenSum}
          </span>
        </div>
      </div>
    </div>
  );
};
export default SumComponent;

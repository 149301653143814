import { useState } from "react"

const StudentComponent = () => {
  const [student, setStudent] = useState("")

  const [students, setStudents] = useState([])

  const addStudent = (e) => {
    const res = document.createElement("p")
    if (student) {
      const isStudentPresent = students.some((s) => s.toLowerCase() === student.toLowerCase())
      if (!isStudentPresent) setStudents((current) => [...current, student])
      else {
        res.classList.add("px-2")
        res.innerText = `${student} already present`
      }
      document.getElementById("student").appendChild(res)
      setTimeout(() => {
        res.style.display = "none"
      }, 1000)
      setStudent("")
    }
  }

  const sortStudentNames = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/v1/backend/studentdata`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ studentsArray: students }),
      }
    )
    const result = await response.json()
    setStudents(result);
  }
  return (
    <div className=" bg-slate-400 flex flex-col items-center  flex-grow w-full">
      <div className="flex w-full justify-around items-center flex-row mt-10 pointer-events-none">
        <img
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
        <h1 className="text-3xl xs:text-5xl font-extrabold text-black   ">
          Sort Student Names
        </h1>
        <img
          src="https://img.icons8.com/arcade/64/javascript.png"
          alt="js-v1"
          className="w-12 h-12 xs:w-16 xs:h-16 sm:w-20 sm:h-20"
        />
      </div>

      <div className="m-6 xs:m-10 xs:mt-5  p-3 xs:p-5 bg-slate-200">
        <div className="flex my-2 ">
          <span className="font-bold text-xl px-2">1. </span>
          <p className="pt-0.5 xs:pt-0 text-sm xs:text-lg leading-relaxed italic hover:not-italic transition-all duration-1000">
            Create an API endpoint using a web framework of your choice (e.g.,
            Flask, Express.js) that allows a user to POST a JSON object with a
            list of student names. Your API should sort the names alphabetically
            and return the sorted list as a JSON response. Include instructions
            on how to run your API and test it.
          </p>
        </div>
      </div>
      <div
        id="student"
        className=" w-[87.2%] xs:w-auto flex gap-2 xs:gap-0 flox-col xs:flex-row flex-wrap justify-center items-center mb-10 bg-white rounded-lg shadow-lg font-bold p-4"
      >
        <input
          type="text"
          className="border-2 border-red-600 p-2 rounded-lg"
          value={student}
          onChange={(e) => {
            setStudent(e.target.value)
          }}
        />

        <button
          type="button"
          className=" bg-lime-300 hover:bg-lime-500 transition-all p-2 px-4 rounded-md m-2 mx-4 border-2 border-red-300 hover:border-red-800"
          onClick={addStudent}
        >
          {" "}
          Add Student
        </button>
        <button
          type="button"
          className=" bg-lime-300 hover:bg-lime-500 transition-all p-2 px-4 rounded-md m-2 mx-4 border-2 border-red-300 hover:border-red-800"
          onClick={sortStudentNames}
        >
          {" "}
          Sort Student Names
        </button>
      </div>
      <div className={`numbers text-red-500 mb-10 w-3/6`}>
      <ul className="flex flex-wrap justify-center xs:items-start flex-row">
         {
           students.map((item, index) => {
             return (
                <li
                  key={index}
                  className="flex m-2 p-2 justify-center capitalize bg-white rounded-xl font-bold"
                >
                  {item}
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}
export default StudentComponent;
